<template>
  <es-table class="sub-analytics" :headers="headers" :items="analytics" date-filter download="analytics.export"
            @downloadData="downloadData" @update="getSubdivisionAnalytics" :importFormats="importFormats">
    <div class="d-flex align-center">
      <h3 class="mr-7">Аналитика по подразделениям</h3>
      <EsFastDatesRange />
    </div>
  </es-table>
</template>

<script>
import {mapActions, mapState} from "vuex";
import exportData from '@/utils/files/requestExport';
import EsFastDatesRange from "@/components/сommon/EsTable/EsFastDatesRange";

export default {
  name: "SubdivisionAnalytics",
  components: {EsFastDatesRange},
  data: () => ({
    importFormats: ['xlsx'],
    headers: [
      {
        text: 'Название подразделения',
        value: 'subdivision.name',
      },
      {
        text: 'Конверсия',
        value: 'conversion',
      },
      {
        text: 'Сделок завершено успешно',
        value: 'completed_deals_count',
      },
      {
        text: 'Новые клиенты',
        value: 'new_clients_count',
      },
      {
        text: 'Учитываемые клиенты в конверсии',
        value: 'processed_clients_count',
      },
      {
        text: 'Не учитываемые клиенты в конверсии',
        value: 'unprocessed_clients_count',
      },
      {
        text: 'Выручка',
        value: 'sales_amount',
      },
      {
        text: 'Кол-во активных диалогов',
        value: 'active_chats_count',
      },
      {
        text: 'Средний чек',
        value: 'average_check',
      },
    ],
  }),
  computed: {
    ...mapState("analytics", ["analytics",]),
  },
  methods: {
    ...mapActions({
      getSubdivisionAnalytics: 'analytics/getSubdivisionAnalytics',
    }),
    downloadData(payload) {
      exportData(this.$store.dispatch, 'analytics/downloadReport', {
        name: "subdivisions",
        args: payload
      }, `Аналитика по подразделениям ${this.$moment(new Date()).format('DD-MM-YYYY')}`);
    },
  },
}
</script>

<style lang="scss" scoped>

.sub-analytics h3 {
  color: #59D7A1;
}
</style>
