<template>
  <div class="date-periods">
    <span :class="{'active': activePeriod(item)}" v-for="(item, index) of fastPeriod" :key="index" @click="pickDates(item.dateFrom, item.dateTo)">{{item.name}}</span>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "EsFastDatesRange",
  data: () => ({
    fastPeriod: [
      {
        name: 'Сегодня',
        dateFrom: new Date(),
        dateTo: new Date(),
      },
      {
        name: 'Неделя',
        dateFrom: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        dateTo: new Date(),
      },
      {
        name: 'Месяц',
        dateFrom: new Date().setMonth(new Date().getMonth()-1),
        dateTo: new Date(),
      },
      {
        name: 'Год',
        dateFrom: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        dateTo: new Date(),
      },
    ],
  }),
  methods: {
    activePeriod (value) {
      return this.$route.query.date_from === this.$moment(value.dateFrom).format('YYYY-MM-DD')
    },
    pickDates (from, to) {
      eventBus.$emit('pickDatesAnalytics', {
        from: this.$moment(from).format('YYYY-MM-DD'),
        to: this.$moment(to).format('YYYY-MM-DD')
      });
    },
    updateQuery () {
      this.$router.replace({
        query: {
          date_from: this.$moment(this.fastPeriod[0].dateFrom).format('YYYY-MM-DD'),
          date_to: this.$moment(this.fastPeriod[0].dateTo).format('YYYY-MM-DD'),
        },
      }).catch(() => {});
    },
    queryInit() {
      if (!this.$route.query.date_from) {
        this.pickDates(this.fastPeriod[0].dateFrom, this.fastPeriod[0].dateTo);
        this.updateQuery();
      }
    }
  },
  mounted () {
    this.queryInit();
  }
}
</script>

<style scoped>

</style>
